import React from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import GUI from '../containers/gui.jsx';
import HashParserHOC from '../lib/hash-parser-hoc.jsx';
import log from '../lib/log.js';
import MessageBoxType from '../lib/message-box.js';
import { useParams } from 'react-router-dom';
import config from '../containers/config';
const onClickLogo = () => {
    window.location = 'https://openblockcc.github.io/wiki/';
};

const onClickCheckUpdate = () => {
    log('User click check update');
};

const onClickUpdate = () => {
    log('User click update');
};

const onAbortUpdate = () => {
    log('User click abort update');
};

const onClickClearCache = () => {
    log('User click clear cahce');
};

const onClickInstallDriver = () => {
    log('User click install driver');
};

const handleTelemetryModalCancel = () => {
    log('User canceled telemetry modal');
};

const handleTelemetryModalOptIn = () => {
    log('User opted into telemetry');
};

const handleTelemetryModalOptOut = () => {
    log('User opted out of telemetry');
};

const onClickAbout = [
    {
        title: (<FormattedMessage
            defaultMessage="About"
            description="Menu bar item for about"
            id="gui.desktopMenuBar.about"
        />),
        onClick: () => log('About')
    },
    {
        title: (<FormattedMessage
            defaultMessage="License"
            description="Menu bar item for license"
            id="gui.desktopMenuBar.license"
        />),
        onClick: () => log('License')
    },
    {
        title: (<FormattedMessage
            defaultMessage="Privacy policy"
            description="Menu bar item for privacy policy"
            id="gui.menuBar.privacyPolicy"
        />),
        onClick: () => log('Privacy Policy')
    },
    {
        title: (<FormattedMessage
            defaultMessage="Data settings"
            description="Menu bar item for data settings"
            id="gui.menuBar.dataSettings"
        />),
        onClick: () => log('Data Settings')
    }
];

const handleShowMessageBox = (type, message) => {
    if (type === MessageBoxType.confirm) {
        return confirm(message); // eslint-disable-line no-alert
    } else if (type === MessageBoxType.alert) {
        return alert(message); // eslint-disable-line no-alert
    }
};

const RenderGUI = () => {
    const WrappedGui = compose(
        AppStateHOC,
        HashParserHOC
    )(GUI);


    const { userId } = useParams();
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const isGuid = guidRegex.test(userId);
    if (userId.length !== 110 && userId.length !== 36) {
        alert("Usuário ou questão inválida!");
        return null;
    }
    config.userId = userId;
    const backpackHostMatches = window.location.href.match(/[?&]backpack_host=([^&]*)&?/);
    const backpackHost = backpackHostMatches ? backpackHostMatches[1] : null;

    const scratchDesktopMatches = window.location.href.match(/[?&]isScratchDesktop=([^&]+)/);
    let simulateScratchDesktop;
    if (scratchDesktopMatches) {
        try {
            simulateScratchDesktop = JSON.parse(scratchDesktopMatches[1]);
        } catch {
            simulateScratchDesktop = scratchDesktopMatches[1];
        }
    }

    return (
        simulateScratchDesktop ?
            <WrappedGui
                canEditTitle
                isScratchDesktop
                onClickAbout={onClickAbout}
                showTelemetryModal
                canSave={false}
                onTelemetryModalCancel={handleTelemetryModalCancel}
                onTelemetryModalOptIn={handleTelemetryModalOptIn}
                onTelemetryModalOptOut={handleTelemetryModalOptOut}
                onAbortUpdate={onAbortUpdate}
                onClickCheckUpdate={onClickCheckUpdate}
                onClickUpdate={onClickUpdate}
                onClickClearCache={onClickClearCache}
                onClickInstallDriver={onClickInstallDriver}
                onShowMessageBox={handleShowMessageBox}
            /> :
            <WrappedGui
                canEditTitle
                backpackVisible
                showComingSoon
                backpackHost={backpackHost}
                canSave={false}
                onClickLogo={onClickLogo}
                onShowMessageBox={handleShowMessageBox}
            />
    );
};

export default RenderGUI;
